<template>
  <div class="login" ref="login">
    <!-- <img
      style="z-index: 0"
      src="https://img1.baidu.com/it/u=442067351,3877875284&fm=253&fmt=auto&app=138&f=JPEG?w=749&h=500"
      alt=""
    /> -->

    <!-- <el-button type="primary" icon="el-icon-search" @click="click_login_btn"
      >登录</el-button
    >
    <el-button type="primary" icon="el-icon-search" @click="click_register_btn"
      >注册</el-button
    > -->
    <div class="modal" :class="{ 'show-modal': is_active }" ref="modal">
      <div class="modal-content login-box-content">
        <div class="box login-box-top">
          <span class="close-button" @click="close_login_box(true)">x</span>
          <div class="login-logo">
            <img :src="login_image" alt="" />
          </div>
          <form action="">
            <div class="invitation-box" style="display: none"></div>
            <div class="login-box-in">
              <div class="login-title">
                <span
                  ><b>{{ current_operate_type.name }}</b></span
                >
              </div>

              <!-- <label
                class="login-form-item"
                :style="{
                  display: get_login_menu_style(this.login_form_menu_list[0]),
                }"
              >
                <input type="text" v-model="nickname" :tabindex="1" class />
                <span
                  ><b>{{ this.login_form_menu_list[0].cn_name }}</b></span
                >
              </label> -->

              <label
                class="login-form-item"
                :style="{
                  display: get_login_menu_style(this.login_form_menu_list[1]),
                }"
              >
                <input
                  type="text"
                  v-model="email"
                  :tabindex="2"
                  spellcheck="false"
                  autocomplete="off"
                  class
                />
                <span
                  ><b>{{ this.login_form_menu_list[1].cn_name }}</b></span
                >
              </label>

              <label
                class="login-form-item"
                :style="{
                  display: get_login_menu_style(this.login_form_menu_list[2]),
                }"
              >
                <input
                  type="text"
                  v-model="password"
                  :tabindex="3"
                  spellcheck="false"
                  autocomplete="off"
                  class
                />
                <span
                  ><b>{{ this.login_form_menu_list[2].cn_name }}</b></span
                >
              </label>

              <div class="verify_code_module">
                <div
                  v-if="is_login"
                  class="yzm"
                  v-html="yanzhengma"
                  @click="click_btn"
                ></div>
              </div>

              <div class="verify">
                <label
                  class="login-form-item"
                  :class="{ 'cerify-module': !is_login }"
                  :style="{
                    display: get_login_menu_style(this.login_form_menu_list[3]),
                  }"
                >
                  <input
                    type="text"
                    v-model="verify_code"
                    :tabindex="4"
                    spellcheck="false"
                    autocomplete="off"
                    class="verify_item"
                  />
                  <span
                    ><b>{{ this.login_form_menu_list[3].cn_name }}</b></span
                  >
                </label>
                <el-row v-if="!is_login" class="verify-code-row">
                  <el-button
                    class="verify-code-btn"
                    type="primary"
                    @click="get_verify_code"
                    >获取验证码</el-button
                  >
                </el-row>
              </div>
              <div class="login-bottom">
                <button class @click="submit_form">
                  {{ this.get_current_login_info().button_name }}
                </button>
              </div>

              <div class="login-tk">
                <p class="login-p">
                  <a href="javascript:void(0)">忘记密码?</a>
                  <span>
                    {{
                      this.get_current_login_info(
                        this.is_login == true ? false : true
                      ).remind_info
                    }}?
                    <a href="javascript:void(0)" @click="change_btn_type">
                      {{
                        this.get_current_login_info(
                          this.is_login == true ? false : true
                        ).name
                      }}
                    </a></span
                  >
                </p>
              </div>
            </div>
            <div class="login-social-button show"></div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import store from "../store.js";
import common from "../common/common.js";
import CONSTANT from "../config/constant.js";
export default {
  name: "Login",
  props: {
    msg: String,
  },
  data() {
    return {
      email: "",
      password: "",
      nickname: "",
      email: "",
      is_active: false,
      yanzhengma: "",
      verify_code: "",
      trace_id: "",
      login_base: {
        login: {
          name: "登录",
          type: "login_item",
          button_name: "快速登录",
          remind_info: "已有账户",
        },
        register: {
          name: "注册",
          type: "register_item",
          button_name: "快速注册",
          remind_info: "新用户",
        },
      },

      is_login: true,
      login_form_menu_list: [
        {
          cn_name: "可爱的昵称",
          en_name: "nickname",
          login_item: "none",
          register_item: "block",
        },
        {
          cn_name: "邮箱",
          en_name: "email",
          login_item: "block",
          register_item: "block",
        },
        {
          cn_name: "密码",
          en_name: "password",
          login_item: "block",
          register_item: "block",
        },
        {
          cn_name: "验证码",
          en_name: "verify_code",
          login_item: "block",
          register_item: "block",
        },
      ],
    };
  },
  methods: {
    async click_btn() {
      try {
        var response = await this.$http.post(
          "http://www.soutudashi.com/captcha",
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        // console.log("图片的地方地方: ", response, response.data);
        this.trace_id = response.data.trace_id;
        this.yanzhengma = response.data.item;
        return response.data;
      } catch (e) {
        // console.log("前端异常拉啦啊啊！！！！！！", e);
      }
    },
    change_btn_type() {
      // console.log("调用change_btn_函数");
      this.is_login = this.is_login == true ? false : true;
      // console.log("name: ", this.get_current_login_info(!this.is_login).name);
      // console.log(
      //   "remind_info: ",
      //   this.get_current_login_info(!this.is_login).remind_info
      // );

      // console.log("调用切换登录状态:", this.is_login);
    },
    get_login_menu_style(value) {
      if (this.is_login) {
        return value[this.login_base.login.type];
      }

      return value[this.login_base.register.type];
    },

    get_current_login_info(login_status) {
      // console.log("dddddddddddddddddd", this.is_login);
      var current_login_status = 1;
      // console.log("login_status: ", login_status);
      if (login_status >= 0 && login_status <= 1) {
        // console.log("进入login_status");
        current_login_status = login_status;
      } else {
        // console.log("进入is_login");
        current_login_status = this.is_login;
      }

      return current_login_status == 1
        ? this.login_base.login
        : this.login_base.register;
    },

    async submit_form(event) {
      event.preventDefault();
      // console.log("点击提交表单");
      // console.log("点击了回复按钮");

      var data = {};
      var email = this.email.trim();
      var password = this.password.trim();
      var nickname = this.nickname.trim();
      // 验证邮箱是否合法
      var email_special_char = await common.verify_emial(email);
      // console.log("邮箱验证结果:", email_special_char);
      var password_special_char = this.text_handle(password);
      var nickname_special_char = this.text_handle(nickname);
      if (this.is_login == true) {
        // console.log("姓名: ", this.email);
        // console.log("password: ", this.password);

        if (email.length > 0 && password.length > 0) {
          if (!email_special_char || !password_special_char) {
            this.message_box(
              "error",
              "不能出现特殊符号，只能允许数字、字母、中文及下划线组成"
            );
            return;
          }
          data = {
            email: email,
            password: password,
            verify_code: this.verify_code,
            trace_id: this.trace_id,
          };

          // console.log("login传入的参数:", data);
        } else {
          this.message_box("error", "登录前请填写用户名和密码哦！");
          return;
        }

        const res = await this.login(data);
        if (res == 66) {
          // console.log("登陆成功");
          // this.$router.push("/");
        } else {
          // console.log("登陆失败");
        }
      } else {
        if (email.length > 0 && password.length > 0) {
          //  && nickname.length > 0
          //判断是否有特殊符号
          if (
            !email_special_char ||
            !password_special_char
            // !nickname_special_char
          ) {
            this.message_box(
              "error",
              "不能出现特殊符号，只能允许数字、字母、中文及下划线组成"
            );
            return;
          }
          data = {
            // nickname: nickname,
            email: email,
            password: password,
            default_avator: Math.ceil(Math.random() * 42),
            verify_code: this.verify_code,
          };
          // console.log("register传入的参数:", data);
        } else {
          this.message_box("error", "注册需要填写昵称，用户名和密码哦");
          return;
        }

        this.register(data);
      }
    },

    async login(data) {
      try {
        var response = await this.$http.post(
          "http://www.soutudashi.com/login",
          {
            headers: { "Content-Type": "application/json" },
            data,
          }
        );

        const request_status = response.data.status;
        // console.log("登录状态~~~~~： ", request_status, response);
        // console.log(
        //   "登录后 response: ",
        //   response,
        //   response.data,
        //   response.data.length,
        //   response.data.status
        // );

        if (request_status != 200) {
          if (request_status == 401) {
            this.message_box("error", "验证码验证失败，请重新输入验证码");
          } else if (request_status == 400) {
            this.message_box("error", "该用户还没有在本站注册，请先注册");
          } else {
            this.message_box("error", "该用户还没有在本站注册，请先注册");
          }
          return 100;
        } else {
          // console.log("接口返回结果response: ");
          // console.log(response);
          // this.$router.replace({ path: "/" });
          this.close_login_box(true);
          const result_data = response.data.data;
          // console.log("登录cookie信息: ", response, result_data);
          // document.cookie(`token:${result_data.token}`);
          common.set_cookie("token", result_data.cookie, 1);
          const userInfoJson = JSON.stringify({
            nick_name: result_data.nick_name,
            avatar_url: result_data.avatar_url,
          });

          localStorage.setItem("userInfo", userInfoJson);

          this.message_box("success", "登录成功");
          // console.log("登录成功: ", response.data);
          // console.log("登录成功查看路径: ", this.$router);
          // 在这里编写需要延迟执行的代码
          // this.change_login_status(true);
          // this.$nextTick(() => {
          //   this.$router.push(this.$route.query.redirect || "/home");
          // });
          this.setLoginStatus();
          window.location.reload();

          // this.$router.go(0);
          return 66;
        }

        //   console.log(response);
      } catch (e) {
        console.log(e);
      }
    },

    setLoginStatus() {
      // console.log("执行设置登录祖杭太才做");
      // 向vuex中设置登录状态为已登录
      store.dispatch("ABsetLogin", true);
      // 设置登录过期时间为1小时后
      const expireTime = new Date().getTime() + 60 * 60 * 1000;
      // this.$store.commit("SET_LOGIN_EXPIRE_TIME", expireTime);

      // console.log("设置登录信息后显示: ", store.state.is_login);
    },

    async get_verify_code() {
      try {
        const data = {
          email: this.email,
          password: this.password,
        };
        var response = await this.$http.post(
          "http://www.soutudashi.com/get_verify_code",
          {
            headers: { "Content-Type": "application/json" },
            data: data,
          }
        );
        // console.log("get_verify_code", response);

        const request_status = await response.data.status;
        if (request_status == 200) {
          this.message_box("success", "验证码已经发送至邮箱，请及时查收");
        } else if (request_status == 400) {
          this.message_box("error", "邮箱验证失败，请输入合法的邮箱");
        } else if (request_status == 410) {
          this.message_box("error", "请输入邮箱和密码后再获取验证码");
        } else {
          this.message_box("error", "验证码发送失败，请检查后再次点击获取");
        }
      } catch (e) {
        // console.log("get_verify_code失败: ", e);
      }
    },
    async register(data) {
      try {
        var response = await this.$http.post(
          "http://www.soutudashi.com/register",
          {
            headers: { "Content-Type": "application/json" },
            data,
          }
        );

        console.log("注册返回结果: ~~~~~", await response.data);
        if (response.data.status == 200) {
          this.$router.replace({ path: "/" });
          this.close_login_box(true);
          this.message_box("success", "注册成功");
          return;
        } else if (response.data.status == 400) {
          this.message_box(
            "error",
            response.data.message
              ? response.data.message
              : "邮箱，密码，验证码不能为空"
          );
        } else if (response.data.status == 401) {
          this.message_box(
            "error",
            response.data.message ? response.data.message : "验证码错误"
          );
        } else {
          this.message_box(
            "error",
            response.data.message ? response.data.message : "注册失败"
          );
        }

        //   console.log(response);
      } catch (e) {
        console.log(e);
      }
    },

    change_login_status(value) {
      this.$parent.change_login_status(value);
    },
    click_login_btn() {
      this.is_login = true;
      this.close_login_box(false);
    },
    click_register_btn() {
      this.is_login = false;
      this.close_login_box(false);
    },
    close_login_box(is_close) {
      if (is_close) {
        this.is_active = false;
      } else {
        this.is_active = true;
      }
    },

    message_box(type, message) {
      this.$message({
        showClose: true,
        message: message,
        type: type,
        customClass: "messageIndex",
      });
    },

    text_handle(str) {
      var rule = /[a-zA-Z0-9_\u4e00-\u9fa5]+/;
      if (str != null && str.trim().length > 0) {
        for (let i = 0; i < str.length; i++) {
          if (!rule.test(str.charAt(i))) {
            return false;
          }
        }

        return true;
      }

      return rule.test(str);
    },
  },

  computed: {
    current_operate_type() {
      // console.log("计算属性:", this.get_current_login_info());
      return this.get_current_login_info();
    },

    login_image() {
      return CONSTANT.LOGIN_IMAGE;
    },
  },

  watch: {
    is_login: {
      handler(newValue, oldValue) {
        this.is_login = newValue;
      },
      deep: true,
    },
  },

  mounted() {
    this.click_btn();

    this.verify_code = "";
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

div {
  display: block;
}

.display {
}

.messageIndex {
  z-index: 999999 !important;
}
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: perspective(1px) scale(1.1);
  transition: visibility 0s linear 0.15s, opacity 0.15s 0s, transform 0.15s;
  z-index: 99999;
  display: flex;
  align-items: center;
}

.show-modal {
  opacity: 1;
  visibility: visible;
  transform: perspective(1px) scale(1);
  transition: visibility 0s linear 0s, opacity 0.15s 0s, transform 0.15s;
}

.modal-content {
  /* background-image: url(https://www.chongwuzhi.com/wp-content/themes/b2/Assets/fontend/images/model-bg.png);  */
  background-color: white;
  width: 350px;
  max-width: 100%;
  padding: 40px 24px 24px 24px;
  margin: 0 auto;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.login-box-content {
  padding: 0;
}

.box {
  background-color: #fff;
  box-shadow: 0px 0px 2px rgb(98 124 153 / 10%);
  position: relative;
}

.login-box-content .login-box-top {
  padding: 30px 24px 24px;
  background-repeat: no-repeat;
  background-size: 100%;
  background: none;
}
.modal-content .close-button {
  font-size: 35px;
}

.close-button {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 18px;
  height: 43px;
  display: block;
  line-height: 34px;
  width: 43px;
  text-align: center;
  cursor: pointer;
  color: #b2bac2;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-logo {
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 27px;
}

img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border: 0;
  vertical-align: text-top;
}

.invitation-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  margin-top: 40px;
}

.login-title {
  color: #b2bac2;
  font-size: 13px;
  margin-bottom: 20px;
  text-align: center;
}

.login-title span {
  display: inline-block;
  padding: 10px;
}

b {
  font-weight: bold;
}
element.style {
  display: none;
}

.login-form-item {
  margin-top: 20px;
  display: flex;
  position: relative;
  align-items: center;
  flex-wrap: wrap;
}

.login-form-item input:focus {
  border-color: #0066ff;
  outline: 0;
  border-radius: 5px;
}

.login-form-item input:focus + span:after,
.login-form-item input[class="active"] + span:after,
.login-form-item input:valid + span:after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: #fff;
  top: 6px;
  position: absolute;
  left: 0;
  z-index: 0;
  opacity: 1;
}

.login-form-item span:after {
  content: "";
  opacity: 0;
}

.login-form-item input:focus + span,
.login-form-item input[class="active"] + span,
.login-form-item input:valid + span {
  left: 10px;
  cursor: default;
}

.login-form-item span {
  position: absolute;
  left: 12px;
  top: -8px;
  font-size: 15px;
  color: #b2bac2;
  cursor: pointer;
  display: block;
  line-height: 1;
  padding: 0 7px;
}

.login-form-item span b {
  position: relative;
  z-index: 1;
}

.login-form-item input {
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  transition: 0.3s;
  border: 1px solid #e8e8e8;
  font-size: 20px;
  height: 48px;
  padding-right: 46px;
}

input,
textarea {
  caret-color: #0066ff;
}

button,
input[type="text"],
input[type="password"],
input[type="number"],
textarea {
  box-sizing: border-box;
  border: 1px solid #ccc;
}

.login-bottom {
  margin-top: 10px;
}

.login-bottom button {
  width: 100%;
  padding: 12px;
  font-size: 14px;
  font-weight: 400;
  display: block;
}

.button,
button {
  background: #0066ff;
  border: 1px solid #0066ff;
  border-radius: 5px;
}

button,
.button {
  border-radius: 4px;
  color: #fff;
  padding: 8px 16px;
  transition: opacity 0.2s;
  position: relative;
  cursor: pointer;
  font-size: 12px;
  margin: 0;
  white-space: nowrap;
}

.login-tk {
  margin-top: 16px;
  text-align: center;
  font-size: 12px;
}

.login-tk p.login-p {
  display: flex;
  justify-content: space-between;
}

.login-tk p {
  display: flex;
  justify-content: space-between;
}

.yzm {
  margin-top: 15px;
}

.verify {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.cerify-module {
  width: calc(50%);
}

.verify-code-row {
  width: calc(50% - 20px);
}
.verify-code-btn {
  /* line-height: 30px; */
  margin-top: 20px;
  height: 46px;
  width: 100%;
}
</style>
