import Vue from "vue";
import Router from "vue-router";
// import Login from "../components/Login.vue";
// import Login from "@/components/Login.vue";
import Page from "../components/Page.vue";
// import BZIndex from "../components/BZIndex.vue";
// import BZDetail from "../components/BZDetail.vue";
// import AboutUs from "../components/AboutUs.vue";
// import RequestPicIndex from "../components/RequestPicIndex.vue";
import HomePage from "../components/HomePage.vue";
// import FeedBack from "../components/FeedBack.vue";
// import SubmitSuccess from "../components/SubmitSuccess.vue";
// import ProblemDetail from "../components/ProblemDetail.vue";
// import CommentReplyBox from "../components/CommentReplyBox.vue";
// import Community from "../components/Community.vue";
// import PublishFriendCircle from "../components/PublishFriendCircle.vue";
// import PublishTutorial from "../components/PublishTutorial.vue";
import {isLoggedIn} from "../common/common.js"
import { EventBus } from "../event-bus.js";
// import SingleComment from "../components/SingleComment.vue";
Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      // component: WebSite,
      component: Page,
      meta: { title: "系统首页" },

      children: [
        {
          path: "/tools",
          component: HomePage,
          // component: () => import("@/components/HomePage.vue"),
        },
        {
          path: "/login",
          name: "Login",
          // component: Login,
          component: () => import("@/components/Login.vue"),
        },
        {
          path: "/",
          // component: BZIndex,
          component: () => import("@/components/BZIndex.vue"),
        },
        {
          path: "/bzindex/detail/:type/:bz_id",
          // component: BZDetail,
          name: "BZDetail",
          component: () => import("@/components/BZDetail.vue"),
          children: [
            {
              path: "",
              component: () => import("@/components/NotFountPage.vue"),
            },
          ],
        },

        {
          path: "/qiutu",
          // component: RequestPicIndex,
          component: () => import("@/components/RequestPicIndex.vue"),
          meta: { requireAuth: true }, // 需要登录才能访问
        },
        {
          path: "/about",
          // component: AboutUs,
          component: () => import("@/components/AboutUs.vue"),
        },
        {
          path: "/feedback",
          // component: FeedBack,
          component: () => import("@/components/FeedBack.vue"),
          children: [
            {
              path: "/",
              component: () => import("@/components/ProblemDetail.vue"),
              // , component: ProblemDetail
            },
          ],
          meta: { requireAuth: true }, // 需要登录才能访问
        },
        {
          path: "/submit_success",
          // component: SubmitSuccess,
          component: () => import("@/components/SubmitSuccess.vue"),
        },
        {
          path: "/pay_result",
          name: "paysuccess",
          // component: SubmitSuccess,
          component: () => import("@/components/PaySuccess.vue"),
        },
        {
          path: "/comment_replybox",
          // component: CommentReplyBox,
          component: () => import("@/components/CommentReplyBox.vue"),
        },
        {
          path: "/singlecomment",
          // component: SingleComment,
          component: () => import("@/components/SingleComment.vue"),
        },
        {
          path: "/community",
          // component: Community,
          component: () => import("@/components/Community.vue"),
          meta: { requireAuth: true }, // 需要登录才能访问
        },
        {
          path: "/charge",
          // component: Community,
          component: () => import("@/components/Charge.vue"),
          meta: { requireAuth: true }, // 需要登录才能访问
        },
        {
          path: "/publish_circle_fc",
          // component: PublishFriendCircle,
          component: () => import("@/components/PublishFriendCircle.vue"),
        },
        {
          path: "/publish_tutorial",
          // component: PublishTutorial,
          component: () => import("@/components/PublishTutorial.vue"),
        },
        {
          path: "/manage",
          // component: PublishTutorial,
          component: () => import("@/components/BackendManage.vue"),
        },
        {
          path: "/compresspic",
          // component: PublishTutorial,
          component: () => import("@/components/CompressPicFunction.vue"),
        },
        {
          path: "/submit_pic_style",
          // component: PublishTutorial,
          component: () => import("@/components/SubmitPicStyle.vue"),
        },
        // {
        //   path: "/test",
        //   // component: PublishTutorial,
        //   component: () => import("@/components/Test.vue"),
        // },
        {
          path: "/clear_bg",
          component: () => import("@/components/ImageRemoveBg.vue"),
        },
        {
          path: "/member_vip",
          // component: PublishTutorial,
          component: () => import("@/components/MemberVip.vue"),
          meta: { requireAuth: true }, // 需要登录才能访问
        },
        {
          path: "/user_center",
          // component: PublishTutorial,
          component: () => import("@/components/UserCenter.vue"),
          meta: { requireAuth: true }, // 需要登录才能访问
          children: [
            {
              path: "/user_center/setting",
              component: () => import("@/components/UserSetting.vue"),
              // , component: ProblemDetail
            },
            {
              path: "/user_center/integral",
              component: () => import("@/components/UserIntegral.vue"),
              // , component: ProblemDetail
            },
            {
              path: "/user_center/timeline",
              component: () => import("@/components/UserIntegral.vue"),
              // , component: ProblemDetail
            },
          ],
        },
        {
          path: "/test6",
          // component: PublishTutorial,
          component: () => import("@/components/Test6.vue"),
          // meta: { requireAuth: true }, // 需要登录才能访问
        },
        {
          path: "/pic_colorful",
          // component: PublishTutorial,
          component: () => import("@/components/PicOnColor.vue"),
          // meta: { requireAuth: true }, // 需要登录才能访问
        },
        // {
        //   path: "/test6",
        //   // component: PublishTutorial,
        //   component: () => import("@/components/PicOnColor.vue"),
        //   // meta: { requireAuth: true }, // 需要登录才能访问
        // },
        {
          path: "/link_to_qrcode",
          // component: PublishTutorial,
          component: () => import("@/components/LinkToQRCode.vue"),
          // meta: { requireAuth: true }, // 需要登录才能访问
        },
      ],
    },
  ],
  mode: "history",
  base: "/",
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    // 需要登录验证的页面
    if (!isLoggedIn()) {
      // 用户未登录，显示登录弹框
      showLoginDialog();
    } else {
      // 用户已登录，允许访问页面
      next();
    }
  } else {
    // 不需要登录验证的页面，直接允许访问
    next();
  }
});


function showLoginDialog() {
  EventBus.$emit("show-login");
}
export default router;
