<template>
  <div class="page">
    <Header class="header"></Header>
    <router-view class="main"></router-view>
    <Footer class="footers"></Footer>
  </div>
</template>

<script type="text/babel">
import Header from "./Header.vue";
import Footer from "./Footer.vue";
export default {
  name: "Page",
  components: { Header, Footer },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
/* .header {
  position: fixed;
  z-index: 999;
} */
.main {
  width: 100%;
  align-items: center;
}
</style>
