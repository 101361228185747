import Vue from "vue";

import App from "./App.vue";
import store from "@/store.js";
// import axios from "axios";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import "@/css/iconfont.css";
import "@/css/main.css";
import router from "@/router/index.js";

import VueLazyload from "vue-lazyload";
import CONSTANT from "@/config/constant";
import axios from './config/http.interceptors'
// axios.defaults.baseURL =
//   CONSTANT.BASE_REQUEST_ADDRESS + ":" + CONSTANT.REQUEST_PORT;
Vue.prototype.$http = axios;

Vue.config.productionTip = false;

Vue.use(ElementUI);

Vue.use(VueLazyload, {
  scrolling: false,
  preLoad: 0.3,
  attempt: 5,
  listenEvents: [
    "scroll",
    "wheel",
    "mousewheel",
    "resize",
    "animationend",
    "transitionend",
    "touchmove",
    "resize",
  ],
  loading: CONSTANT.LOADING_IMAGE,
  error:
    CONSTANT.GET_IMAGE_ADDRESS +
    encodeURIComponent("http://image.soutudashi.com/default_avator/10.jpg"),
  throttleWait: 200,
  observer: true,
});

const Apps = new Vue({
  render: (h) => h(App),
  store,
  router,
}).$mount("#app");
