import Vue from "vue";
import Login from "./components/Login.vue";

// 创建一个新的Vue实例作为事件总线
const eventBus = new Vue();

export const EventBus = eventBus;

// EventBus.$on("show-login", () => {
//   // 假设你有一个id为'dynamic-component'的元素用于挂载组件
//   const componentInstance = new Vue(Login).$mount();
//   componentInstance.click_login_btn();
//   //   document.getElementById("login-popup").appendChild(componentInstance.$el);
//   const loginPopupEl = document.getElementById("app"); // 获取挂载点元素
//   if (loginPopupEl) {
//     loginPopupEl.appendChild(componentInstance.$el); // 将Login组件的元素添加到挂载点
//   }
// });

EventBus.$on("show-login", () => {
  // console.log("执行show-login");
  // 在Vue 2中，使用 vm.$mount 方法来挂载Vue实例
  const loginInstance = new Vue(Login).$mount("#app"); // 创建并挂载Login组件的Vue实例

  //   console.log("登录组件:", loginInstance);
  eventBus.$nextTick(() => {
    loginInstance.click_login_btn();
  });
  const loginPopupEl = document.getElementById("login-popup"); // 获取挂载点元素
  //   const loginPopupEl = document.getElementById("app"); // 获取挂载点元素
  if (loginPopupEl) {
    loginPopupEl.appendChild(loginInstance.$el); // 将Login组件的元素添加到挂载点
  }
});
