// http.interceptors.js 或者其他你存放axios配置的文件
import axios from 'axios';
import router from '../router/index.js'; // 引入vue-router实例
import {get_token,clear_cookie,clearLoginInfo} from '../common/common.js'
// 退出登录并清除cookie的函数
const logoutAndClearCookies = () => {
            //   this.message_box("error", "登陆过期了，请重新登陆");
          clear_cookie('token')
          clearLoginInfo()
          // this.$store.commit("SET_LOGIN", false);
          window.sessionStorage.removeItem("vuex")
          // window.onlo
          router.go(0);
          return ;
  // 退出登录操作，使用vue-router跳转到登录页
//   router.push({ path: '/login' });
//   // 清除cookie
//   document.cookie.split(";").forEach((c) => {
//     document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
//   });
};

// 设置响应拦截器
axios.interceptors.response.use(response => {
  // 检查是否是受保护的路径

//   const protectedRouters = ['/qiutu','/feedback','/community','/charge','/member_vip','/user_center']
//   console.log("响应值!!!!!!!!!!!!!!!!!!!!： ",response.data.status)
//   const protectedPaths = [];
//   const currentPath = router.currentRoute.path ; // response.config.url;
// console.log("相应拦截器",currentPath,router,router.currentRoute.path)
  // 如果响应状态码为402，并且当前请求的路径是受保护的路径之一
//   if (response.data.status === 405 && protectedRouters.some(path => currentPath.startsWith(path))) { // response.status === 402 && 
//     console.log("进入保护状态的路由 并获取402状态")
//     // logoutAndClearCookies();
//   }

  return response;
}, error => {

    const protectedRouters = ['/qiutu','/feedback','/community','/charge','/member_vip','/user_center']
    const currentPath = router.currentRoute.path ;
    if (error.response.status === 405 && protectedRouters.some(path => currentPath.endsWith(path))) { // response.status === 402 && 
        // console.log("失败状态下进入保护状态的路由 并获取402状态",error.response.status,protectedRouters.some(path => currentPath.endsWith(path)),router.currentRoute.path ,error.response)
        logoutAndClearCookies();
      }
    
  // 对于错误处理，可以在这里统一处理
  return Promise.reject(error);
});

export default axios;