const CONSTANT = {};

// 基础的图片地址
CONSTANT.OSS_BASE_URL = "http://image.soutudashi.com/";

// 关于我们的文字介绍
CONSTANT.ANOUT_US_INTROUCTION =
  '在这个数字时代，您的虚拟存在与个性展现同样重要。欢迎来到一个专为个性化和自我表达设计的综合性网站——“搜图大师soutudashi”。\
“搜图大师soutudashi”不仅仅是一个网站，它是您在线世界的完美伙伴。我们为您提供一系列精心挑选的高清壁纸，让您的屏幕充满色彩和活力。无论是自然风光、抽象艺术、电影场景还是动漫角色，我们的库存都能满足您的需求。\
想要在社交网络中脱颖而出？浏览我们的头像库，找到独一无二的自我形象。从经典卡通人物到现代设计图标，从简约风格到复杂图案，我们确保您总能找到那一个最能代表您自己的头像。\
图库不能满足您的需求？没关系，"搜图大师soutudashi"将提供要图功能，用户可以提出自己想要的图的文字描述，最好能有目标效果图，我们将为您生成多张相似图片供您挑选。\
最后，但同样重要的是，我们的社区功能让您能够与来自全国各地的用户交流和分享，欢迎参与到网站的持续改进中来。\
“搜图大师soutudashi”是一个关于创造力、分享和连接的社区。我们的使命是让每一位用户都能在这个网络世界中找到归属感，并充分表达自己。加入我们，开启您的个性化网络之旅。';

CONSTANT.GET_IMAGE_ADDRESS = "http://www.soutudashi.com/get_image/";

// 邮箱地址
CONSTANT.EMIL_ADDRESS = "soutudashi@163.com";

// 图片加载动画
CONSTANT.LOADING_IMAGE =
  CONSTANT.GET_IMAGE_ADDRESS + encodeURIComponent("default/loading.gif");

CONSTANT.LOGIN_IMAGE =
  CONSTANT.GET_IMAGE_ADDRESS + encodeURIComponent("default/login_logo.png");

CONSTANT.HOVER_LEFT_IMAGE =
  CONSTANT.GET_IMAGE_ADDRESS + encodeURIComponent("statis/left.ico");

CONSTANT.HOVER_right_IMAGE =
  CONSTANT.GET_IMAGE_ADDRESS + encodeURIComponent("statis/right.ico");

CONSTANT.BASE_REQUEST_ADDRESS = "http://39.108.65.6";

CONSTANT.REQUEST_PORT = 3000;

CONSTANT.BG_1 =
  CONSTANT.GET_IMAGE_ADDRESS +
  encodeURIComponent("bg/20771591-6dae-4970-9bc6-46db70c9b51c.webp");

CONSTANT.BG_2 =
  CONSTANT.GET_IMAGE_ADDRESS +
  encodeURIComponent("bg/b921fe2d-b14f-4c4b-bec9-925b8d4a003b.webp");
module.exports = CONSTANT;
