import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    is_login: false,
  },
  getters: {},
  mutations: {
    SET_Login(state, is_login) {
      state.is_login = is_login;
    },
  },
  actions: {
    ABsetLogin({ commit }, is_login) {
      commit("SET_Login", is_login);
    },
  },
  modules: {},
  // plugins: [
  //   createPersistedState({
  //     // 存储方式：localStorage、sessionStorage、cookies
  //     storage: window.sessionStorage,
  //     // 存储的 key 的key值
  //     // key: "is_login",
  //     reducer(val) {
  //       return {
  //         is_login: JSON.parse(val.is_login), // 仅持久化demo部分的状态。
  //       };
  //     },
  //   }),
  // ],

  plugins: [
    createPersistedState({
      // 存储方式：localStorage、sessionStorage、cookies
      storage: window.sessionStorage,
      reducer(state) {
        // 只有当is_login状态发生变化时，才返回一个新的state对象
        // 这里仅持久化is_login和可能相关的user信息
        let login_obj = JSON.parse(window.sessionStorage.getItem("vuex"));

        let login_status = false;
        if (login_obj == undefined || login_obj == null || login_obj == "") {
          login_status = false;
        } else {
          login_status = JSON.parse(window.sessionStorage.getItem("vuex"))[
            "is_login"
          ];
        }
        if (state.is_login !== login_status) {
          return {
            is_login: state.is_login,
          };
        }
        // 如果is_login未变化，则不持久化任何状态
        return null;
      },
    }),
  ],
});
