// const dns = require("node:dns");

const Common = {};

Common.getUserInfo = function () {
  const userInfoJson = localStorage.getItem("userInfo");

  console.log("设置好了   ", userInfoJson);
  return userInfoJson == undefined ? {} : JSON.parse(userInfoJson);
};

Common.clearLoginInfo = function (arr) {
  for (let i = localStorage.length - 1; i >= 0; i--) {
    const key = localStorage.key(i);
    index_list = ["userInfo"];
    if (index_list.includes(key)) {
      //  console.log("清除登录信息了：", key);
      localStorage.removeItem(key);
    }
  }
};

Common.generate_unique_flag = function () {
  if (!localStorage.getItem("uniqueId")) {
    const uniqueId = Math.random().toString(36).substr(2, 9);
    localStorage.setItem("uniqueId", uniqueId);
  }
};
Common.message_box = function (type, message) {
  this.$message({
    showClose: true,
    message: message,
    type: type,
    customClass: "messageIndex",
    duration:1000
  });
};

Common.get_token = function (name) {
  console.log("get_token: ",name)
  if(name == null || name == undefined){
    return "";
  }

  const cookie_origin = document.cookie;
  console.log("get_token: ",cookie_origin,cookie_origin=="")
  if(cookie_origin == "" || cookie_origin == null || cookie_origin == undefined){
    return "";

  }
  let cookie_arr = document.cookie.split(";");
  if (cookie_arr.length > 0) {
    for (let i = 0; i < cookie_arr.length; i++) {
      const key_value_map = cookie_arr[i].split("=");
      if (key_value_map.length >= 1) {
        const key = key_value_map[0].trim();
        const value = key_value_map[1].trim();
        if (key === name) {
          return value;
        }
      }
    }

    return "";
  } else {
    return "";
  }
};

Common.set_cookie = function (name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

Common.clear_cookie = function (name) {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
};

// 验证邮箱
// 邮箱格式验证
Common.isValidEmailFormat = function isValidEmailFormat(email) {
  const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return re.test(email);
};

// 验证邮箱整体是否合法及邮箱后缀是否有效
Common.verify_emial = async function (email) {
  if (!Common.isValidEmailFormat(email)) {
    // callback(new Error("Invalid email format"));
    return null;
  } else {
    return email;
  }
};

Common.isLoggedIn = function(){
    // 在这里编写判断用户是否已登录的逻辑，返回true表示已登录，false表示未登录
    // 这里只是一个示例，您需要根据您的实际情况进行修改
  
    const login_obj = Common.get_token("token")
    let login_status = false;
    console.log("的呢过了tokenssssss: ",login_obj)
    if (login_obj == undefined || login_obj == null || login_obj.trim() == "") {
      console.log("进入falsesss")
      return false;
    } else {
      console.log("进入trussse")
      return true
  }
};


Common.dataURItoBlob = function(dataURI){
  const byteString = atob(dataURI.split(",")[1]); // 解码Base64
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0]; // 获取MIME类型
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
},

Common.circle_list = [
  {
    left: "6px",
    top: "6px",
  },
  {
    left: "65.2px",
    top: "0px",
  },
  {
    left: "127.4px",
    top: "0px",
  },
  {
    left: "189.6px",
    top: "0px",
  },
  {
    left: "251.8px",
    top: "0px",
  },
  {
    left: "314x",
    top: "0px",
  },
  {
    left: "376.2px",
    top: "0px",
  },
  {
    left: "438.4px",
    top: "0px",
  },
  {
    left: "500.6px",
    top: "0px",
  },
  {
    left: "562.8px",
    top: "0px",
  },
  {
    left: "623px",
    top: "6px",
  },
  // 右侧竖线
  {
    left: "628px",
    top: "62.2px",
  },
  {
    left: "628px",
    top: "124.4px",
  },
  {
    left: "628px",
    top: "186.6px",
  },
  {
    left: "628px",
    top: "248.8px",
  },
  {
    left: "628px",
    top: "311px",
  },
  {
    left: "628px",
    top: "373.2px",
  },
  {
    left: "628px",
    top: "435.4px",
  },
  {
    left: "628px",
    top: "497.6px",
  },
  {
    left: "628px",
    top: "559.8px",
  },
  {
    left: "622px",
    top: "623px",
  },
  // 底下横线
  {
    left: "559.8px",
    top: "630px",
  },
  {
    left: "497.6px",
    top: "630px",
  },
  {
    left: "435.4px",
    top: "630px",
  },
  {
    left: "373.2px",
    top: "630px",
  },
  {
    left: "311px",
    top: "630px",
  },
  {
    left: "248.8px",
    top: "630px",
  },
  {
    left: "186.6px",
    top: "630px",
  },
  {
    left: "124.4px",
    top: "630px",
  },
  {
    left: "62.2px",
    top: "630px",
  },
  {
    left: "6px",
    top: "622px",
  },
  // 左侧竖线
  {
    left: "2px",
    top: "559.8px",
  },
  {
    left: "2px",
    top: "497.6px",
  },
  {
    left: "2px",
    top: "435.4px",
  },
  {
    left: "2px",
    top: "373.2px",
  },
  {
    left: "2px",
    top: "311px",
  },
  {
    left: "2px",
    top: "248.8px",
  },
  {
    left: "2px",
    top: "186.6px",
  },
  {
    left: "2px",
    top: "124.4px",
  },
  {
    left: "2px",
    top: "62.2px",
  },
];
module.exports = Common;
