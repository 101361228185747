<template>
  <div class="header">
    <div class="nav">
      <div class="nav-menu-bar">
        <ul class="nav-list">
          <li>
            <a href="/" @click="change_module_color($event)">首页</a>
          </li>
          <li>
            <a href="/tools" @click="change_module_color($event)">功能</a>
          </li>
          <!-- <li>
            <a href="/community" @click="change_module_color($event)">社区</a>
          </li> -->
          <li>
            <a href="/about" @click="change_module_color($event)">关于我们</a>
          </li>
          <!-- <li>
            <a href="/feedback" @click="change_module_color($event)"
              >问题反馈</a
            >
          </li> -->
        </ul>
      </div>

      <div class="nav-login-bar" v-if="isLoggedIn">
        <a
          class="user_title"
          href=""
          v-on:click="click_a"
          @mouseenter="showElement($event)"
          @mouseleave="hideElement($event)"
          @click="to_user_page"
        >
          <div class="base_info">
            <img :src="get_user_info.avatar_url" alt="" />

            <div class="user_info">
              <span class="user_nick">{{ get_user_info.nick_name }}</span>
              <!-- <div class="user_level"><span> lv1.电磁力 </span></div> -->
            </div>
          </div>
        </a>
        <div
          class="my_page"
          v-show="isDisplayElement"
          @mouseenter="showElement($event)"
          @mouseleave="hideElement($event)"
        >
          <ul>
            <li class="user_menu">
              <a class="account" @click.prevent="to_user_page"
                ><span>账号等级</span></a
              >
            </li>
            <li class="user_menu">
              <a class="psersonal_info" @click.prevent="to_user_page"
                ><span>个人中心</span></a
              >
            </li>
            <li class="user_menu">
              <a class="my_subject" @click.prevent="to_user_page"
                ><span>我的教程</span></a
              >
            </li>
            <li class="user_menu">
              <a class="my_collect" @click.prevent="to_user_page"
                ><span>我的收藏</span></a
              >
            </li>
            <li class="user_menu">
              <a class="friends_circle" @click.prevent="to_user_page"
                ><span>我的时光轴</span></a
              >
            </li>
            <li class="user_menu">
              <a class="fans" @click.prevent="to_user_page"
                ><span>我的粉丝</span></a
              >
            </li>
            <li class="user_menu">
              <a class="attention" @click.prevent="to_user_page"
                ><span>我的关注</span></a
              >
            </li>
            <li class="user_menu">
              <a class="attention" href="http://www.soutudashi.com/member_vip"
                ><span>积分充值</span></a
              >
            </li>
            <li class="user_menu">
              <!-- <div class="btn"> -->
              <a class="logout-btn" @click="logout">退出登录</a>
              <!-- </div> -->
            </li>
          </ul>
        </div>
        <!-- </div> -->
      </div>
      <div class="nav-login-bar" v-if="!isLoggedIn">
        <el-button
          class="btn-style"
          type="primary"
          icon="el-icon-search"
          @click="login_btn_click_event"
          >登录</el-button
        >
        <el-button
          class="btn-style"
          type="primary"
          icon="el-icon-search"
          @click="register_btn_click_event"
          >注册</el-button
        >
        <Login ref="login"></Login>
      </div>

      <div class="fun_area">
        <a href="/publish_circle_fc" @click="click_publish_circle_btn($event)"
          ><el-button type="primary" round>发布笔记</el-button></a
        >
        <!-- 
        <a
          href="/publish_tutorial"
          @click="click_publish_tutorial_btn($event)"
          style="font-size: 25px; color: black"
          ><i class="iconfont icon-caishichang-"></i
        ></a> -->
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import Login from "./Login.vue";
import { getUserInfo, clearLoginInfo, clear_cookie,get_token } from "../common/common.js";
import CONSTANT from "../config/constant.js";

let _this = this;
//根据自己需要来监听对应的key
window.addEventListener("setItemEvent", function (e) {
  //e.key : 是值发生变化的key
  //e.newValue : 是可以对应的新值
  if (e.key === "userInfo") {
    // console.log(e.newValue);
    this.userInfo = e.newValue;
  }
});

export default {
  name: "Header",
  components: { Login },
  data() {
    return {
      // is_login: false,
      // userInfo: { nick_name: "", user_name: "", avatar_url: "" },
      // userInfo: this.get_user_info(),
      isDisplayElement: false,
    };
  },
  methods: {
    login_out() {
      // console.log("调用退出登录了~~~~~~~~~~~~~~~~~~~~~~~~~~~~");
      clear_cookie("token");
      this.$store.dispatch("ABsetLogin", false);
      window.location.reload();
    },
    login_btn_click_event() {
      this.$nextTick(() => {
        this.$refs.login.click_login_btn();
      });
    },

    register_btn_click_event() {
      this.$refs.login.click_register_btn();
    },

    // change_login_status(value) {
    //   this.is_login = value;
    // },

    click_a(event) {
      // console.log("点击链接了");
      event.preventDefault();
    },

    showElement(e) {
      // console.log("触发showelement函数拉 ", e);
      this.isDisplayElement = true;
      // console.log("显示拉！！！！！！！！！！！！！");
      // console.log(e);
      // document.getElementsByClassName("my_page").style.display = "block";
    },

    hideElement(e) {
      // console.log("触发hideelement函数拉 ", e);
      this.isDisplayElement = false;
      // console.log("隐藏拉！！！！！！！！！！！！！");
      // console.log(e);
      // document.getElementsByClassName("my_page").style.display = "none";
    },

    click_test() {
      // console.log("teststt");
    },

    // 退出登录
    logout() {
      clearLoginInfo();
      // console.log("清除登录信息了");
      // this.is_login = false;
      this.isDisplayElement = false;
      // 设置登录状态为退出登录
      this.login_out();
    },

    to_user_page() {
      this.$router.push("/user_center");
    },

    // 点击发布按钮
    click_publish_circle_btn(e) {
      // e.preventDefault(); // 阻止默认事件
    },

    // 发布教程
    click_publish_tutorial_btn(e) {},

    change_module_color(e) {
      // console.log("当前选中的节点: ", e);
      e.target.parentNode.style.backgroundColor = "gray";
    },
  },

  created() {
    // const isLogin = localStorage.getItem("is_login");
    // if (isLogin !== null) {
    //   this.$store.commit("setIsLogin", isLogin === "true"); // 根据localStorage中的值更新Vuex store的状态
    // }
    // this.$store.commit("SET_LOGIN", localStorage.getItem("is_login"));
    // console.log("调用created函数", this.login_status);
    // this.userInfo = getUserInfo();
    // console.log("created函数获取user_info", this.userInfo);
    // this.is_login = this.login_status;
  },

  beforeCreate() {
    // console.log("beforecreated -------------");
  },

  mounted() {
    // console.log("mounted -------------");
    // window.addEventListener("setItem", (e) => {
    // console.log("家庭setitem,", e, e.key == "userInfo", this.userInfo);
    // if (e.key == "userInfo") {
    // this.userInfo = e.newValue;
    // }
    // console.log("复制后: ", this.userInfo);
    // });
  },

  beforeMount() {
    // console.log("beforemounted -------------");
  },

  updated() {
    // console.log("执行header的updated方法：", this.get_user_info());
    // this.userInfo = getUserInfo();
  },
  watch: {
    // is_login: {
    //   handler(newValue, oldValue) {
    //     this.is_login = newValue;
    //     console.log("监控拉~~~~~~~~~~~~~~~~~~~~~~~~~~~~~");
    //     console.log("监控拉~~~~~~~~~~~~~~~~~~~~~~~~~~~~~");
    //     console.log("监控拉~~~~~~~~~~~~~~~~~~~~~~~~~~~~~");
    //     console.log(
    //       "监控拉~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ ",
    //       this.is_login,
    //       newValue,
    //       oldValue
    //     );
    //   },
    //   deep: true,
    // },
    isDisplayElement: {
      handler(newValue, oldValue) {
        // console.log("isDisplayElement值发生变化了，", oldValue, newValue);
      },
      deep: true,
    },
  },

  computed: {
    // ...mapGetters(["isLoggedIn"]),
    // isLoggedIn() {
    //   return 
    // },

     isLoggedIn() {
  // 在这里编写判断用户是否已登录的逻辑，返回true表示已登录，false表示未登录
  // 这里只是一个示例，您需要根据您的实际情况进行修改

  const login_obj = get_token("token")
  let login_status = false;
  // console.log("的呢过了token: ",login_obj)
  if (login_obj == undefined || login_obj == null || login_obj.trim() == "") {
    // console.log("进入false")
    return false;
  } else {
    // console.log("进入true")
    return  true
}
     },
    get_user_info() {
      let user_obj = getUserInfo();
      let user_data = {};
      if (user_obj.avatar_url == null || user_obj.avatar_url == "") {
        user_data.avatar_url =
          CONSTANT.GET_IMAGE_ADDRESS +
          encodeURIComponent("default_avator/1.jpg");
      } else {
        user_data.avatar_url =
          CONSTANT.GET_IMAGE_ADDRESS + encodeURIComponent(user_obj.avatar_url);
      }
      user_data.nick_name = user_obj.nick_name;
      // console.log(
      //   "当前的地址:",
      //   user_data,
      //   CONSTANT.GET_IMAGE_ADDRESS,
      //   CONSTANT
      // );
      return user_data;
    },
    // userInfo() {
    //   console.log("userInfo计算属性被调用", getUserInfo());
    //   return getUserInfo();
    // },

    // login_status() {
    //   return getLoginStatus();
    // },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  height: 46px;
  top: 0;
  left: 0;
  background-color: #ffffff;
  position: fixed;
  z-index: 11;
}

.header .nav {
  width: 1200px;
  max-width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nav-menu-bar {
  flex: 2;
  line-height: 46px;
}
.nav-login-bar {
  /* flex: 1; */
}

.fun_area {
  flex: 0.5;
}

.fun_area > a {
  text-decoration: none;
  /* background-color: blue; */
}

.nav-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
}

.nav-list a,
.nav-list li {
  text-decoration: none;
}
.nav-list li {
  flex: 1;
}

.nav-list li:hover {
  color: red;
}
.btn-style {
}

.user_title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  /* height: 46px; */
  /* width: 40px;
  height: 100%;
  background-color: aqua; */
}

.nav-login-bar a {
  text-decoration: none;
}
.user_title .base_info {
  height: 46px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-sizing: border-box; */
}

.base_info .user_info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.base_info .user_info .user_nick {
  flex: 0.9;
}

/* .base_info .user_info .user_level {
  box-sizing: border-box;
  flex: 0.9;
} */
.user_title .base_info img {
  height: 40px;
  margin-right: 5px;
}

.my_page {
  width: 150px;
  height: auto;
  background-color: white;
  position: absolute;
  display: block;
  z-index: 9999999;
  border: 1px solid gray;
  /* top: 46px; */
}

.my_page ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
  box-sizing: border-box;
  /* box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.3); */
}
.my_page ul li {
  list-style: none;
  display: block;
  width: 100%;
  margin-top: 5px;
  z-index: 99999999;
  box-sizing: border-box;
  letter-spacing: 1px;
}

.my_page span {
  color: black;
  font-size: 20px;
}

.my_page ul li:hover {
  background-color: red;
}

.user_level {
  height: 20px;
  width: 100%;
  border: 1px pink solid;
  border-radius: 10px;
  background-color: white;
}
</style>
