<template>
  <div class="wow">
    <!-- <img src="../assets/bg2.png" class="bg-image" alt="" /> -->
    <div :style="cssVars" class="bg-image">
      <div class="title"><h1>欢迎来到搜图大师--www.soutudashi.com</h1></div>
    </div>
    <div class="interacte-buttons">
      <div
        class="menu-item"
        v-for="(item, index) in interacte_btn"
        :key="index"
      >
        <a :href="item.link"
          ><div class="interacte-button">
            <i
              class="icon-f iconfont"
              :class="item.icon"
              style="display: block; font-size: 100px; line-height: 100px"
            ></i>
            <span>{{ item.label }}</span>
          </div></a
        >
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import CONSTANT from "../config/constant.js";
export default {
  data() {
    return {
      bg_image: CONSTANT.BG_1,
      interacte_btn: [
        {
          icon: "icon-tuxiang",
          label: "壁纸",
          link: "/",
        },
        {
          icon: "icon-huabi",
          label: "求图",
          link: "/qiutu",
        },
        {
          icon: "icon-shequ",
          label: "朋友圈",
          link: "/community",
        },
        {
          icon: "icon-wentifankui",
          label: "问题反馈",
          link: "/feedback",
        },
        {
          icon: "icon-compress-image",
          label: "图片压缩",
          link: "/compresspic",
        },
        {
          icon: "icon-icon",
          label: "色彩搭配",
          link: "/color_match",
        },
        {
          icon: "icon-xiuxiankafeiyule",
          label: "解闷小工具",
          link: "/jiemen_tools",
        },
        {
          icon: "icon-changlianjiezhuanerweima",
          label: "长链接转二维码",
          link: "/link_to_qrcode",
        },
        {
          icon: "icon-zhinengkoutu",
          label: "一键抠图",
          link: "/clear_bg",
        },
        {
          icon: "icon-wendang",
          label: "黑白照片上色",
          link: "/pic_colorful",
        },
        {
          icon: "icon-juanzeng",
          label: "支持本站",
          link: "/charge",
        },
      ],
    };
  },
  mounted() {},
  computed: {
    cssVars() {
      return {
        backgroundImage: `url(${this.bg_image})`,
        backgroundSize: "100% 200px",
        // '--container-height': this.containerHeight
      };
    },
  },
};
</script>

<style scoped>
.wow .interacte-buttons {
  display: flex;
  width: calc(80%);
  justify-content: left;
  align-items: center;
  margin: 20px auto;
  gap: calc(10%);
  flex-wrap: wrap;
}

/* .bg-image {
  background-image: var(--bg-image);
  background-size: 100% 200px;
} */

.bg-image > .title {
  height: 200px;
  line-height: 200px;
}
/* .bg-image {
  width: 100%;
  height: 200px;
  object-fit: fill;
  margin-bottom: 20px;
} */
.interacte-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.interacte-button > span {
  height: 40px;
  line-height: 40px;
}

.icon-f {
  padding: 10px auto;
}
.menu-item {
  width: 150px;
  height: 150px;
  padding: 20px 30px;
  /* margin: 20px 40px; */

  transition: transform 0.3s ease-in-out;
}

.menu-item:hover {
  /* transform: translateY(-10px); */
  background-color: rgba(150, 150, 150, 0.7);
  /* transition: all 1s; */
}
a {
  text-decoration: none;
  color: black;
}
</style>
